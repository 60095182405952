<template>
  <validation-observer ref="purchase-invoice-profile-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Número de factura de provedor"
                label-for="purchase-invoice-number"
              >
                <b-form-input
                  id="purchase-invoice-number"
                  :value="model.provider_code"
                  name="purchase-invoice-number"
                  placeholder="Número de factura de provedor"
                  @input="update('provider_code', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Fecha factura"
                label-for="purchase-invoice-date"
              >
                <b-form-datepicker
                  id="purchase-invoice-date"
                  :value="model.invoice_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha factura"
                  :label-help="null"
                  @input="update('invoice_date', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Vencimiento factura"
                label-for="purchase-invoice-date-expiration"
              >
                <b-form-datepicker
                  id="purchase-invoice-date-expiration"
                  :value="model.expiration_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha factura"
                  :label-help="null"
                  @input="update('expiration_date', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Fecha computo"
                label-for="purchase-invoice-computation-date"
              >
                <b-form-datepicker
                  id="purchase-invoice-computation-date"
                  :value="model.computation_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha computo"
                  :label-help="null"
                  @input="update('computation_date', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="origin === 'invoice'">
              <b-form-group
                label="Pedido"
                label-for="purchase-invoice-order"
              >
                <BaseSelect
                  id="purchase-invoice-order"
                  :resource="$data.$constants.RESOURCES.RESOURCE_ORDERS"
                  label="number"
                  :value="model.order"
                  @input="update('order', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor"
                  label-for="purchase-invoice-provider"
                >
                  <BaseSelect
                    id="purchase-invoice-provider"
                    :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
                    :value="model.provider"
                    @input="update('provider', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Contacto de facturación"
                label-for="purchase-invoice-contact"
              >
              <BaseSelect
                id="purchase-invoice-contact"
                label="fullname"
                :disabled="selectedContactIsDisabled"
                :key="selectedContactKey"
                :resource="$data.$constants.RESOURCES.RESOURCE_CONTACTS"
                :http-body="{ provider_id: providerId }"
                :value="model.contact"
                @input="update('contact', $event)"
              />
              </b-form-group>
            </b-col>
            <b-col v-if="origin !== 'fra'" sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Categoría"
                rules="required"
              >
                <b-form-group
                  label="Categoría"
                  label-for="purchase-invoice-category-group"
                >
                  <CategoryGroupSelect
                    id="purchase-invoice-category-group"
                    :value="model.category_group"
                    :state="$getValidationState(validationContext)"
                    @input="update('category_group', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Estado"
                rules="required"
              >
                <b-form-group
                  label="Estado"
                  label-for="purchase-invoice-status"
                >
                  <StatusSelect
                    id="purchase-invoice-status"
                    :value="model.status"
                    :type="statusType"
                    @input="update('status', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="origin === 'fra'">
              <validation-provider
                v-slot="validationContext"
                name="Partida"
                rules="required"
              >
                <b-form-group
                  label="Partida"
                  label-for="purchase-invoice-item"
                >
                  <BaseSelect
                    id="purchase-invoice-item"
                    :resource="$data.$constants.RESOURCES.RESOURCE_ACCOUNTING_ITEMS"
                    :value="model.accounting_item"
                    :type="statusType"
                    @input="update('accounting_item', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="origin === 'fra'"
              sm="12"
              class="pb-2"
            >
              <b-form-checkbox
                v-model="model.no_invoice"
                value="1"
                :unchecked-value="0"
                @input="update('no_invoice', $event)"
              >
                No facturas
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row align-v="center">
            <b-col v-if="origin !== 'fra'" sm="12">
              <validation-provider
                v-slot="validationContext"
                name="División"
                rules="required"
              >
                <b-form-group
                  label="División"
                  label-for="purchase-invoice-division"
                >
                  <BaseSelect
                    id="purchase-invoice-division"
                    :value="model.division"
                    :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
                    http-method="get"
                    @input="update('division', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="origin !== 'fra'" sm="12">
              <b-form-group
                label="Job"
                label-for="purchase-invoice-job"
              >
                <BaseSelect
                  id="purchase-invoice-job"
                  :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
                  :value="model.job"
                  :clearable="false"                  
                  @selected="handleJobSelect"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="showSelectedContract || model.job_contract || isMaintenance" sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Contrato"
                rules="required"
              >
                <b-form-group
                  label="Contrato"
                  label-for="purchase-invoice-contract"
                >
                  <BaseSelect
                    id="purchase-invoice-contract"
                    label="name"
                    :value="model.job_contract"
                    :resource="$data.$constants.RESOURCES.RESOURCE_JOBS_CONTRACTS"
                    :disabled="!model.job"
                    :clearable="false"
                    :key="selectedContractKey"
                    :http-body="{
                      job_id: model.job ? model.job.id : null,
                    }"
                    @input="update('job_contract', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="3">
              <b-form-group
                label="Retención"
                label-for="purchase-invoice-retention"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="purchase-invoice-retention"
                    :value="model.retention_percent"
                    placeholder="Retención"
                    type="number"
                    @input="update('retention_percent', $event)"
                  />
                  <b-input-group-append is-text>
                    %
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="5">
              <b-form-group
                label="Vencimiento retención"
                label-for="purchase-invoice-retention-due-date"
              >
                <b-form-datepicker
                  id="purchase-invoice-retention-due-date"
                  :value="model.retention_expiration"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Vencimiento retención"
                  :label-help="null"
                  @input="update('retention_expiration', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-checkbox
                v-model="model.withholding_document_received"
                value="1"
                :unchecked-value="0"
                @input="update('withholding_document_received', $event)"
              >
                Documento de retención recibido
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Observaciones"
                label-for="observations"
              >
                <b-form-textarea
                  :value="model.observations"
                  name="observations"
                  placeholder="Observaciones"
                  rows="7"
                  max-rows="7"
                  @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Adjuntar Archivos">
                <FileDropZone
                  ref="documentation-input"
                  :files="files"
                  @removed-file="removedFiles.push($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import ApiRestService from '@/api/base-api'
import JobsApi from '@/api/jobs-api'
import { PURCHASE_INVOICES_STATUS_TYPE } from '@/api/status-api'
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'PurchaseInvoiceProfileForm',
  components: {
    BaseSelect,
    FileDropZone,
    StatusSelect,
    CategoryGroupSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: PURCHASE_INVOICES_STATUS_TYPE,
      removedFiles: [],
      selectedContactIsDisabled: true,
      selectedContactKey: null,
      showSelectedContract: false,
      selectedContractKey: null,
    }
  },
  computed: {
    ...mapGetters({
      tab: 'purchaseInvoice/getTabSelected',
    }),
    files() {
      return this.model.documentation
    },
    origin () {
      return this.tab === 0 ? 'invoice' : 'fra'
    },
    providerId () {
      return this.model?.provider?.id
    },
    isMaintenance() {
      return this.model?.division?.group == 'M' ? true : false;
    }
  },
  watch: {
    async providerId (newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        this.update('contact', null)
      }
      this.selectedContactIsDisabled = true
      if (newValue) {
        const response = await ApiRestService.list({ filters: { provider_id: this.providerId } }, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        if (response.data.length) {
          this.selectedContactIsDisabled = false
          this.selectedContactKey = `${new Date()}`
        }
      }
    },
  },
  methods: {
    validateForm() {
      return this.$refs['purchase-invoice-profile-form'].validate()
    },
    getDocumentationData() {
      return { documentation: this.$refs['documentation-input'].getFiles(), removedFiles: this.removedFiles }
    },
    async handleJobSelect(job) {
      this.update('job', job)
      this.model.job = job

      const response = await JobsApi.get(job.id)
      const { job_contracts } = response.data

      if(job_contracts.length > 0) {        
        const contractActive = job_contracts.find(({ active }) => active === 1)
        this.model.job_contract = contractActive || {}
        this.update('job_contract', contractActive || {})
        this.showSelectedContract = true
      } else {
        this.showSelectedContract = false
        this.model.job_contract = null
        this.update('job_contract', null)
      }
    },
  },
  mounted() {
    this.update('computation_date', moment().format('YYYY-MM-DD'))
  },
}
</script>
